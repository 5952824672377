<template lang="">
    <validation-observer ref="simpleRules">
        <b-form>
            <b-row>
                <b-col cols="12" md="6">
                    <b-card class="h-100">
                        <div class="d-flex">
                            <feather-icon icon="UserIcon" size="19" />
                            <h4 class="mb-0 ml-50">Contact</h4>
                        </div>
                        <b-row class="mt-1">
                            <b-col cols="12" md="6">
                                <b-form-group label="Nom complete" label-for="nom" >
                                    <validation-provider #default="{ errors }" name="nom" rules="required">
                                        <b-form-input id="nom" v-model="interData.nom_complet" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6">
                                <b-form-group label="Code postal" label-for="zipcode" >
                                    <validation-provider #default="{ errors }" name="zipcode" rules="required">
                                        <b-form-input id="zipcode" v-model="interData.zipcode" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="6">
                                <b-form-group label="Email" label-for="email" >
                                    <validation-provider #default="{ errors }" name="email" rules="required|email">
                                        <b-form-input id="email" v-model="interData.email" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6">
                                <b-form-group label="Téléphone" label-for="tel" >
                                    <validation-provider #default="{ errors }" name="tel" rules="required">
                                        <b-form-input id="tel" v-model="interData.tel" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="12">
                                <b-form-group label="Adresse" label-for="address" >
                                    <validation-provider #default="{ errors }" name="address" rules="required">
                                        <b-form-textarea id="address" v-model="interData.address" rows="2" no-resize />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>

                <b-col cols="12" md="6">
                    <b-card class="h-100">
                        <div class="d-flex">
                            <feather-icon icon="ToolIcon" size="19" />
                            <h4 class="mb-0 ml-50">Intervention</h4>
                        </div>
                        <b-row class="mt-1">
                            <b-col cols="12" md="6">
                                <b-form-group label="Catégorie" label-for="nom" >
                                    <validation-provider #default="{ errors }" name="service" rules="required"> 
                                        <v-select
                                            v-model="interData.service_id"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            :options="services"
                                            label="name"
                                            :reduce="val => val.id"
                                            :clearable="false"
                                            input-id="service"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6">
                                <b-form-group label="Type de problème" label-for="sub_service" >
                                    <validation-provider #default="{ errors }" name="sub_service" rules="required">
                                        <v-select
                                            v-model="interData.sub_service_id"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            :options="filtredSubServices"
                                            label="titre"
                                            :reduce="val => val.id"
                                            :clearable="false"
                                            input-id="service"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="6">
                                <b-form-group label="Etat d'urgence" label-for="urgence" >
                                    <validation-provider #default="{ errors }" name="urgence" rules="required">
                                        <v-select
                                            v-model="interData.urgence"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            :options="[{id: 1, name: 'En Urgence'},{id: 0, name: 'Sur Rendez-Vous'}]"
                                            label="name"
                                            :reduce="val => val.id"
                                            :clearable="false"
                                            input-id="service"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6">
                                <b-form-group label="Date d'intervention" label-for="date" >
                                    <validation-provider #default="{ errors }" name="date" rules="required">
                                        <flat-pickr
                                            v-model="interData.date_intervention"
                                            class="form-control"
                                            :config="{ enableTime: true,dateFormat: 'd-m-Y H:i'}"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="12">
                                <b-form-group label="Description problème" label-for="description" rules="required">
                                    <validation-provider #default="{ errors }" name="description">
                                        <b-form-textarea id="description" v-model="interData.description" rows="3" no-resize />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
            <b-card class="h-100 mt-1">
                <b-row>
                    <b-col cols="12" md="6" class="text-left">
                        <b-form-group label="Côut de contact" label-for="cout" label-cols="2" >
                            <validation-provider #default="{ errors }" name="cout" rules="required">
                                <b-input-group append="€" class="input-group-merge w-25" >
                                    <b-form-input id="cout" v-model="interData.cout" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" class="text-right">
                        <b-button variant="primary" @click="save"> Enregistrer </b-button>
                    </b-col>
                </b-row>
            </b-card>
        </b-form>
    </validation-observer>
</template>
<script>

    import {
        BRow, BCol, BTable, BBadge, BCard, BForm, BButton, BFormInput, BFormGroup, BFormTextarea, BInputGroup, 
    } from 'bootstrap-vue'
    import { mapGetters, mapActions } from "vuex"
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import formValidation from '@core/comp-functions/forms/form-validation'
    import { required, email } from '@validations'
    import vSelect from 'vue-select'
    import flatPickr from 'vue-flatpickr-component'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


    export default {

        components: {
            BRow, BCol, BTable, BBadge, BCard, BForm, BButton, BFormInput, BFormGroup, BFormTextarea, BInputGroup,
            ValidationProvider, ValidationObserver, vSelect, flatPickr, ToastificationContent
        }, 

        data() {
            return {
                interData: {
                    id: null,
                    date_intervention: null,
                    urgence: 1,
                    description: null,
                    cout: null,
                    service_id: null,
                    sub_service_id: null,
                    address: null,
                    zipcode: null,
                    nom_complet: null,
                    tel: null,
                    email: null
                },
                
            }
        },

        computed: {
            ...mapGetters('services', {'services' : 'GET_ITEMS'}),
            ...mapGetters('subServices', {'sub_services' : 'GET_ITEMS'}),

            filtredSubServices() {
                let s =  this.sub_services.filter(item => {
                    return item.service.id == this.interData.service_id
                })

                return s
            }
        },

        methods: {
            ...mapActions('demandes', {'fetch' : 'getItem', 'update' : 'updateItem'}),
            ...mapActions('services', {'fetchServices' : 'fetchItems'}),
            ...mapActions('subServices', {'fetchSubServices' : 'fetchItems'}),


            notification(variant, title, text) {
                this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    icon: variant == 'danger' ? 'XCircleIcon' : 'CheckCircleIcon',
                    text: text,
                    variant
                },
                })
            },

            save() {
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        this.update(this.interData)
                        .then((result) => {
                            if(result.data.success){
                                this.notification('success',  'Modification', 'L\'intervention est bien modifié')
                                this.$router.push({ name: 'interventions-view', params: { id: this.interData.id } });
                            }else
                                this.notification('danger',  'Modification', 'L\'intervention  n\'est pas modifié')
                        })
                    }
                })
            }
        },

        created() {
            this.fetchServices()
            this.fetchSubServices()

            this.fetch(this.$route.params.id)
                .then((result) => {
                    this.interData = {
                        id: result.data.id,
                        date_intervention: result.data.date,
                        urgence: result.data.urgence,
                        description: result.data.description,
                        cout: result.data.cout,
                        service_id: result.data.service.service.id,
                        sub_service_id: result.data.service.id,
                        address: result.data.contact.address,
                        zipcode: result.data.contact.zipcode,
                        nom_complet: result.data.contact.nom,
                        tel: result.data.contact.tel,
                        email: result.data.contact.email
                    }
                })
                .catch(() => {
                    this.interData = null
                })
        },

        watch: {
            'interData.service_id'(val) {
                if(this.filtredSubServices.findIndex(subservice => subservice.id == this.interData.sub_service_id) == -1)
                    this.interData.sub_service_id = null
            }
        }
        
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>